import styled from '@emotion/styled';

const GolfsCardStyle = styled('div')`
    .mobileView {
        display: none;
    }
    .iconHotelorGolf {
        color: #12b897;
    }
    .iconDetail {
        color: #12b897;
        margin-right: 10px;
    }
    .discriptRoundOfGofl {
        display: flex;
        margin-bottom: 10px;
        width: 100%;
        align-items: center;
    }
    .timeOfRound {
        margin-left: 10px;
    }
    .detailRoundGolf {
        margin-left: 20px;
    }
    .iconDetailGolf {
        visibility: hidden;
    }
    .endIcon {
        margin-left: 5px;
    }
    .hotelAndGoflView {
        border-radius: 4px;
        background: #fff;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        padding: 24px;
        display: flex;
        justify-content: space-between;
        margin-top: 20x;
        margin-bottom: 27x;
    }
    .imgHotelOrGofl {
        width: 171px;
        height: 171px;
        gap: 16px;
        border-radius: 6px;
        margin-right: 24px;
    }
    .viewInforGolf {
        display: flex;
        width: 85%;
    }
    .reviewSeller {
        width: 100%;
        justify-content: space-between;
    }
    @media screen and (max-width: 430px) {
        .desktopView {
            display: none;
        }
        .mobileView {
            display: block;
        }
        .hotelAndGoflViewMobile {
            border-radius: 4px;
            background: #fff;
            box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
            margin-bottom: 27px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
        .imgHotelOrGofl {
            width: 100%;
            height: 223px;
            object-fit: cover;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
        .bodyViewMobileCard {
            padding: 19px;
        }
        .headerMobile {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
        .contentInfor{
            margin-top: 25px;
        }
        .discriptRoundOfGofl{
            margin-top: 25px;
        }
        .btnSeeDetail{
            margin-top: 20px;
        }
        .reviewSeller {
            width: 100%;
            justify-content: space-between;
            padding: 20px 30px 20px 30px;
        }
    }
`;

export default GolfsCardStyle;
