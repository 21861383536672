import React, { useState } from 'react';
import HotelCardStyle from './style';
import images from 'src/assets/image';
import icons from 'src/assets/icon';
import ModalComponent from '../modalCustom';
import ModalTouristDetination from 'src/pages/QuoteDetailPage/ItineraryInDetail/ModalTouristDestination';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faCircleUser } from '@fortawesome/free-solid-svg-icons';

interface hotelCardProps {
    dataHotel?: any;
}

const HotelCardComponent: React.FC<hotelCardProps> = ({ dataHotel }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const hotelName = dataHotel?.name || '';
    const hotelAddress = dataHotel?.address || '';
    const hotelStartDate = dataHotel?.startDate || '';
    const hotelRoomType = dataHotel?.roomType || null;
    const comment = dataHotel?.comments || [];
    return (
        <HotelCardStyle>
            <div className="desktopView">
                <div className="hotelAndGoflView">
                    <div className="viewInforHotel">
                        <img
                            alt=""
                            className="imgHotelOrGofl"
                            src={images.hotelImage}
                        />
                        <div>
                            <div className="titleItem">
                                Stay at {hotelName}{' '}
                            </div>
                            <div className="addressHotelOrGolf">
                                {hotelAddress?.county}
                            </div>
                            <div className="contentInfor">
                                {dataHotel?.duration} Nights on{' '}
                                {moment(hotelStartDate).format(
                                    'ddd Do MMMM YYYY',
                                )}
                            </div>
                            {hotelRoomType?.map((item: any, index: any) => {
                                return (
                                    <div className="discriptHotel" key={index}>
                                        <FontAwesomeIcon
                                            className="iconDetail"
                                            icon={faBed}
                                        />{' '}
                                        x{item?.numOfPassenger}{' '}
                                        {item?.nameRoomType}, {item?.boardBasis}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="iconAndBtnItem">
                        <img
                            alt=""
                            className="iconHotelorGolf"
                            src={icons.hotelIcon}
                        />
                        <div
                            className="btnSeeDetail"
                            onClick={() => showModal()}
                        >
                            See details
                        </div>
                    </div>
                </div>
                {comment?.map((item: any, index: any) => {
                    return (
                        <div className="reviewSeller" key={index}>
                            <div>"{item?.text}"</div>
                            <div className="avatarSeller">
                                <FontAwesomeIcon
                                    className="avatar"
                                    icon={faCircleUser}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="mobileView">
                <div className="hotelAndGoflViewMobile">
                    <img
                        alt=""
                        className="imgHotelOrGofl"
                        src={images.hotelImage}
                    />
                    <div className="bodyViewMobileCard">
                        <div className="headerMobile">
                            <div className="titleItem">
                                Stay at {hotelName}{' '}
                            </div>
                            <img
                                alt=""
                                className="iconHotelorGolf"
                                src={icons.hotelIcon}
                            />
                        </div>
                        <div className="addressHotelOrGolf">
                            {hotelAddress?.county}
                        </div>
                        <div className="contentInfor">
                            {dataHotel?.duration} Nights on{' '}
                            {moment(hotelStartDate).format('ddd Do MMMM YYYY')}
                        </div>
                        {hotelRoomType?.map((item: any, index: any) => {
                            return (
                                <div
                                    className="discriptHotel mobileDiscription"
                                    key={index}
                                >
                                    <FontAwesomeIcon
                                        className="iconDetail"
                                        icon={faBed}
                                    />{' '}
                                    <div className="contentItemMobile">
                                        x{item?.numOfPassenger}{' '}
                                        {item?.nameRoomType}, {item?.boardBasis}
                                    </div>
                                </div>
                            );
                        })}
                        <div
                            className="btnSeeDetail"
                            onClick={() => showModal()}
                        >
                            See details
                        </div>
                    </div>
                </div>
                {comment?.map((item: any, index: any) => {
                    return (
                        <div className="reviewSeller" key={index}>
                            <div>"{item?.text}"</div>
                            <div className="avatarSeller">
                                <FontAwesomeIcon
                                    className="avatar"
                                    icon={faCircleUser}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
            <ModalComponent
                width={525}
                visible={isModalVisible}
                onClose={handleClose}
                title="Stay at The Belfry"
                content={<ModalTouristDetination />}
            />
        </HotelCardStyle>
    );
};

export default HotelCardComponent;
